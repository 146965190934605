<template>
  <div class="login-box__form">
    <div class="login-box__block">
      <div class="form-group form-group--center">
        <label for="email" class="form-label">Логин</label>
        <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
             :class="{ 'has-error': !form.email && touched }">
          <input id="email" ref="email" name="email" v-model="form.email" @keyup="validate" @change="validate"
                 @keyup.enter="submit" type="text" class="form-control" placeholder="Введите логин" autocomplete="on">
        </div>
      </div>
    </div>
    <div class="login-box__block">
      <div class="form-group form-group--center">
        <label for="password" class="form-label">Пароль</label>
        <div class="form-field form-field--text form-field--2 form-field--m form-field--primary"
             :class="{ 'has-error': !form.password && touched }">
          <input id="password" name="password" v-model="form.password" @keyup="validate" @change="validate"
                 @keyup.enter="submit" type="password" class="form-control" placeholder="Введите пароль" autocomplete="on">
        </div>
      </div>
    </div>

    <div v-if="error" class="login-box__message">
      <span class="form-error">{{error}}</span>
    </div>

    <div class="login-box__submit">
      <button @click="submit" :disabled="!isValid" class="btn btn--1 btn--m btn--primary" type="button">
        <span class="btn__label">Войти</span>
      </button>

      <a href="/esia-auth" class="c-btn c-btn--primary c-btn--m c-btn--light">
        <span class="c-btn__icon">
          <svg class="svg-clr" width="24" height="24">
            <use :href="'/img/sprite-clr-' + appVersion + '.svg#logo-gosuslugi-24'" />
          </svg>
        </span>
        <span class="c-btn__label">Войти через Госуслуги</span>
      </a>
    </div>
  </div>
</template>

<script>
import packageInfo  from '../../../../package';
import {focusInput} from '../_Functions/focusInput';

export default {
  props: {
    error: String
  },
  data() {
    return {
      appVersion: packageInfo.version,
      isValid: true,
      focused: false,
      form: {
        email: null,
        password: null,
      },
      touched: false
    };
  },
  mounted() {
    focusInput();
    this.$refs['email'].focus();
  },
  methods: {
    validate() {
      this.isValid = false;
      this.touched = true;
      let isEmpty = false;
      for (var key in this.form) {
        if (this.form[key] == null || this.form[key] === "") {
          isEmpty = true;
        }
      }
      if (!isEmpty) {
        this.isValid = true;
      }
    },
    submit() {
      this.validate();
      if (this.isValid) {
        this.$emit('submit', this.form)
      }
    },
  },
};
</script>