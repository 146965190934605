<template>
  <details class="s-nav__sub" :open="path.startsWith('/requests')">
    <summary class="s-link">
      <span class="icon icon--m">
        <svg class="svg" width="24" height="24">
          <use :href="'/img/sprite-' + appVersion + '.svg#tasks-24'" />
        </svg>
      </span>
      <span class="s-link__label">Задачи</span>
    </summary>
    <ul class="s-nav__sublist">
      <li class="s-nav__subitem">
        <a href="/requests" class="s-sublink" :class="{'s-sublink--active': path === '/requests' && !project_id}">
          <span class="s-sublink__icon">
            <svg class="svg-clr" width="20" height="20">
              <use :href="'/img/sprite-clr-' + appVersion +'.svg#tasks'"/>
            </svg>
          </span>
          <span class="s-sublink__label">ВСЕ</span>
          <span class="s-sublink__qty">{{allRequestsAmount}}</span>
        </a>
      </li>
      <li class="s-nav__subitem">
        <a href="/requests?project_id=personal" class="s-sublink" :class="{'s-sublink--active': path.startsWith('/requests') && project_id == 'personal'}">
          <span class="s-sublink__icon">
            <svg class="svg-clr" width="20" height="20">
              <use :href="'/img/sprite-clr-' + appVersion +'.svg#male'"/>
            </svg>
          </span>
          <span class="s-sublink__label">Личное <span class="lock"><span class="lock__arc"></span>
            <span class="lock__frame"></span></span>
          </span>
          <span class="s-sublink__qty">{{personalRequestAmount}}</span>
        </a>
      </li>
      <li v-for="project in projects" class="s-nav__subitem">
        <a :href="'/requests?project_id=' + project.id" class="s-sublink"
           :class="{'s-sublink--active': path.startsWith('/requests') && project_id == project.id }" >
          <span v-if="project.logo" class="s-sublink__logo">
            <img :src="project.logo" width="20" height="20" alt="">
          </span>
          <span v-else-if="project.emoji" class="s-sublink__emoji">{{project.emoji}}</span>
          <span v-else class="s-sublink__logo">
            <span class="icon icon--m">
              <svg class="svg" width="24" height="24">
                <use :href="'/img/sprite-' + appVersion + '.svg#logo-blank-24'" />
              </svg>
            </span>
          </span>
          <span class="s-sublink__label">{{project.name}}
            <span v-if="project.lock" class="icon icon--es">
              <svg class="svg" width="12" height="12">
                <use :href="'/img/sprite-' + appVersion + '.svg#lock-12'" />
              </svg>
            </span>
          </span>
          <span class="s-sublink__qty">{{project.requestsAmount}}</span>
        </a>
      </li>
    </ul>
  </details>
</template>

<script>
import packageInfo  from '../../../../package';
import Echo from "laravel-echo";
import axios from 'axios';
import VueCookies from 'vue-cookies';

export default {
  props: {
    data: Object,
    user: Object
  },
  data() {
    return {
      authToken: null,
      appVersion: packageInfo.version,
      allRequestsAmount: null,
      personalRequestAmount: null,
      path: location.pathname,
      projects: [],
      project_id: null
    }
  },
  mounted() {
    this.projects = this.data.projects;
    this.allRequestsAmount = this.data.allRequestsAmount;
    this.personalRequestAmount = this.data.personalRequestAmount;

    this.authToken = VueCookies.get('jwt-token');

    const urlParams = new URLSearchParams(window.location.search);
    this.project_id = urlParams.get('project_id');

    let self = this;
    this.echoServer = new Echo({
      broadcaster: "socket.io",
      host: window.location.hostname,
      transports: ["websocket", "poling"],
      auth: {
        headers: {
          Authorization: "Bearer " + this.authToken
        }
      }
    });
    this.echoServer
    .private(process.env.BROADCAST_CHANNELS_PREFIX + "_sidebar_projects_update." + this.user.id)
    .listen("SidebarProjectsBroadcast", function (e) {
      self.getProject(e)
    });

    this.echoServer.connector.socket.on('connect', function () {
      self.getProjects();
    });
  },
  methods: {
    getProject(e) {
      this.allRequestsAmount = e.sidebar.allRequestsAmount;
      this.personalRequestAmount = e.sidebar.personalRequestAmount;
      this.projects = e.sidebar.projects;
    },
    getProjects() {
      axios.get('/sidebar-projects').then((res) => {
        this.allRequestsAmount = res.data.allRequestsAmount;
        this.personalRequestAmount = res.data.personalRequestAmount;
        this.projects = res.data.projects;
        let sidebarSettings = document.getElementById('sidebar-settings');
        if (sidebarSettings) {
          sidebarSettings.classList.remove('hidden');
        }
        let layoutPreloader = document.getElementById('layout-preloader');
        if (layoutPreloader) {
          layoutPreloader.classList.add('hidden');
        }
      })
    }
  }
};
</script>